import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../components/Header';
import { sanityClient } from '../sanity';
import { useContextValue } from '../context';

function Contact() {
  const videoRef = useRef();
  const [muted, setMuted] = useState(true);
  const [data, setData] = useState({});
  const { links } = useContextValue();
  const userName = useRef();
  const userEmail = useRef();
  const message = useRef();
  const formRef = useRef();

  const PublicKey = 'SY_xi4WAFBp47HChM';
  const ServiceId = 'service_4vvmvcp';
  const TemplateId = 'template_tbmpm2p';

  useEffect(() => {
    let subscribe = true;
    const gql = `*[_type == "contact"][0]{
            ...
          }`;
    const fetchData = async () => {
      const res = await sanityClient.fetch(gql);
      setData(res);
    };
    if (subscribe) {
      fetchData();
    }
    videoRef.current.play();

    return () => {
      subscribe = false;
    };
  }, []);

  const handleSendEmail = (e) => {
    e.preventDefault();

    if (
      userName.current.value.length > 0 &&
      userEmail.current.value.length > 0 &&
      message.current.value.length > 0
    ) {
      emailjs.sendForm(ServiceId, TemplateId, formRef.current, PublicKey).then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  return (
    <div className="min-h-screen relative z-10 w-screen">
      <div className="absolute z-10 w-full">
        <Header index="contact" />
      </div>

      <div className="flex md:h-screen flex-col md:flex-row justify-between">
        <div className="md:flex-1">
          <video
            ref={videoRef}
            muted={muted}
            src={data?.video}
            onClick={() => setMuted((prev) => !prev)}
            loop
            controls={false}
            playsInline
            className="relative top-14 md:top-0 cursor-pointer h-full w-full  md:h-full object-contain md:object-cover"
          />
        </div>

        <div className="flex mt-20 md:mt-0 mx-4 md:mr-0 md:ml-20 space-y-12 flex-col flex-1 items-start justify-center md:pt-32 wow animate__slideInRight">
          <div className="text space-y-4 w-10/12 mx-auto md:mx-0 md:w-full">
            <h1 className="text-black text-4xl font-semibold block w-full">
              {data?.header}
            </h1>
            <a href={`mailto:${data?.email}`} className="">
              <span className="text-black block text-lg py-3">
                {data?.email}
              </span>
            </a>
            <a href={`tel:${data?.tel}`}>
              <span className="text-black block text-lg">Tel: {data?.tel}</span>
            </a>
          </div>

          <form
            ref={formRef}
            onSubmit={handleSendEmail}
            className="flex flex-col items-start w-10/12 mx-auto md:mx-0 md:w-full space-y-3"
          >
            <input
              ref={userName}
              type="text"
              name="from_name"
              style={{ background: '#746f6f85' }}
              placeholder="Name"
              className="w-full md:w-3/4 hover:bg-transparent focus:bg-transparent uppercase p-4 focus:border-primary-200 hover:border-primary-200 text-black placeholder:text-black border-transparent text-sm border-2 outline-none bg-transparent"
            />
            <input
              type="text"
              value="DJ Kaboo"
              readOnly
              name="to_name"
              hidden
            />
            <input
              ref={userEmail}
              type="email"
              name="sender_email"
              style={{ background: '#746f6f85' }}
              placeholder="email"
              className="w-full md:w-3/4 hover:bg-transparent focus:bg-transparent uppercase p-4 focus:border-primary-200 hover:border-primary-200 text-black placeholder:text-black border-transparent text-sm border-2 outline-none bg-transparent"
            />
            <textarea
              ref={message}
              rows="5"
              name="message"
              type="text"
              style={{ background: '#746f6f85' }}
              placeholder="message"
              className="w-full md:w-3/4   hover:bg-transparent focus:bg-transparent p-4 focus:border-primary-200 hover:border-primary-200 text-black placeholder:text-black border-transparent text-sm border-2 outline-none bg-transparent"
            />
            <button
              className="border-none outline-none transition-all duration-500 w-full md:w-fit md:px-20 py-4 text-black bg-primary-200 uppercase"
              type="submit"
            >
              send
            </button>
          </form>

          {window.innerWidth < 768 && (
            <div className="w-7/12 mx-auto py-10 flex items-center justify-center mt-20 space-x-5 ">
              <a href={links?.spotifylink} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-green-400 fa-brands text-xl fa-spotify " />
              </a>
              <a href={links?.instagramlink} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-red-600 opacity-80 text-xl fa-brands fa-instagram" />
              </a>
              <a href={links?.youtubelink} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-red-600 text-xl fa-brands fa-youtube" />
              </a>
              <a href={links?.applemusic} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-[#E85CF4] text-xl fa-brands fa-itunes-note" />
              </a>
              <a href={links?.soundcloudlink} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-orange-500 text-xl fa-brands fa-soundcloud" />
              </a>
              <a href={links?.bandcamplink} target="_blank" rel="noreferrer">
                <i className="cursor-pointer text-black hover:text-blue-400 text-xl fa-solid fa-compass" />
              </a>
              {/* <i className="cursor-pointer text-black hover:text-green-400 opacity-75 fa-brands mx-3 text-xl fa-spotify "></i>
                                <i className="cursor-pointer text-black hover:text-[#AB7EEB] mx-3 opacity-75 text-xl fa-brands fa-instagram"></i>
                                <i className="cursor-pointer text-black hover:text-red-600 mx-3 opacity-75 text-xl fa-brands fa-youtube"></i>
                                <i className="cursor-pointer text-black hover:text-[#E85CF4] mx-3 opacity-75 text-xl fa-brands fa-itunes-note"></i>
                                <i className="cursor-pointer text-black hover:text-orange-500 mx-3 opacity-75 text-xl fa-brands fa-soundcloud"></i>
                                <i className="cursor-pointer text-black hover:text-blue-300 mx-3 opacity-75 text-xl fa-solid fa-compass"></i> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;
