import createClient from '@sanity/client';

const token =
  'skXyIL18zTZBfu7OOCCQwEaRjJwGyVlIzLCcYqrrbn7rW7qNd3rLzyFKGpMY0T9yeV6c5t30oYOAqwuaaGc8dIpDm4leG3gYS1eXLJcmtkR69IL9yEtEtwKAgl2af7X6GduRBG7vd4NAbbBvQGL6e6DkB7YQMHwPBhqEa04c9AE3SxGAW56F';

export const config = {
  dataset: 'production',
  projectId: 'ht3ak2rc',
  apiVersion: '2021-10-21',
  useCdn: true,
  token,
};

export const sanityClient = createClient(config);

// export const urlFor = (source)=> createImageUrlBuilder(config).image(source);

// export const useCurrentUser = createCurrentUserHook(config);
