import { useContextValue } from '../context';

function SongCard({ songData }) {
  const { setModalState } = useContextValue();

  return (
    <div
      className="w-[150px] px-3 md:w-[280px] h-[150px] md:h-[280px] group rounded-xl overflow-hidden grayscale hover:grayscale-0 ease-in-out duration-300 transition-all"
      onClick={() => setModalState(songData)}
    >
      <div className="w-full h-full rounded-xl p-3">
        <img
          src={songData?.thumbnail?.asset?.url}
          alt="thumbnail"
          className=" rounded-xl cursor-pointer h-full object-cover"
        />
      </div>
    </div>
  );
}

export default SongCard;
