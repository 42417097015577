import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="min-h-screen flex flex-col items-center relative z-10">
      <h1 className="text-red-700 text-6xl font-bold mt-20">Not Found | 404</h1>
      <Link
        to="/"
        className="text-white text-2xl uppercase mt-32 px-20 py-2 bg-red-500 rounded-3xl"
      >
        Go Home
      </Link>
    </div>
  );
}

export default NotFound;
