import React from 'react';
import styles from './Audio.module.css';

function AudioWave() {
  return (
    <div className={styles.icon}>
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}

export default AudioWave;
