import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactPlayer from 'react-player/youtube';
import { useContextValue } from '../context';

function SongModal() {
  const { modalState, setModalState } = useContextValue();

  return (
    <div className="modalView fixed flex justify-center items-center rounded-xl top-0 bg-[#0000003e] left-0 overflow-hidden w-full h-full z-[20]">
      <div className="relative w-11/12 rounded-xl overflow-hidden md:w-3/12 lg:w-4/12 bg-primary-100">
        <CancelIcon
          style={{ fontSize: 34 }}
          onClick={() => setModalState({})}
          className="absolute cursor-pointer py-1 z-30 top-0 right-0 text-primary-200"
        />
        {/* <div className="w-full bg-primary-100 relative pb-6"> */}
        {/*  <div className="w-full cursor-pointer object-contain h-full relative"> */}
        {/*    <ReactPlayer */}
        {/*      url={modalState?.releaselink} */}
        {/*      playsinline */}
        {/*      light */}
        {/*      width="100%" */}
        {/*      height="100%" */}
        {/*      playing */}
        {/*    /> */}
        {/*  </div> */}
        {/* </div> */}
        <div className="w-full flex flex-col bg-primary-100 rounded-b-xl pb-3 items-center justify-center border-b-2">
          <h1 className="text-center uppercase font-bold lg:text-xl pt-4">
            {modalState?.releasetitle}
          </h1>
          <span className="text-center block text-sm lg:text-lg">
            Choose Music Service
          </span>
        </div>

        <div className="flex flex-col justify-center md:h-full mx-6">
          <div className="row flex py-3 justify-between border-gray-700 items-center">
            <i className="fa-brands ml-1 fa-youtube text-3xl" />
            <h1 className="ml-2  md:text-lg flex-1">Youtube</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a
                target="_blank"
                href={modalState?.releaselink}
                rel="noreferrer"
              >
                Play Now
              </a>
            </button>
          </div>

          <div className="row flex py-3 justify-between border-gray-700 items-center border-t">
            <i className="fa-brands ml-1 fa-spotify text-3xl" />
            <h1 className="ml-2  md:text-lg flex-1">Spotify</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a target="_blank" href={modalState?.spotify} rel="noreferrer">
                Play Now
              </a>
            </button>
          </div>

          <div className="row flex py-3 justify-between items-center border-gray-700 border-t">
            <i className="fa-brands ml-1 fa-itunes-note text-3xl" />
            <h1 className="ml-2  md:text-lg flex-1">Apple Music</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a href={modalState?.spotify} target="_blank" rel="noreferrer">
                Play Now
              </a>
            </button>
          </div>

          <div className="row flex py-3 justify-between items-center border-gray-700 border-t">
            <i className="fa-brands ml-1 fa-bandcamp text-3xl" />
            <h1 className="ml-2  md:text-lg flex-1">bandcamp</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a href={modalState?.spotify} target="_blank" rel="noreferrer">
                Play Now
              </a>
            </button>
          </div>

          <div className="row flex py-3 justify-between items-center border-gray-700 border-t">
            <img
              src={require('../assets/anghami.png')}
              className="w-10 h-10 grayscale"
              alt="logo"
            />
            <h1 className="ml-2  md:text-lg flex-1">Anghami</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a href={modalState?.spotify} target="_blank" rel="noreferrer">
                Play Now
              </a>
            </button>
          </div>

          <div className="row flex py-3 justify-between items-center border-gray-700 border-t">
            <i className="ml-1 fa-brands fa-soundcloud text-3xl" />
            <h1 className="ml-2  md:text-lg flex-1">Soundcloud</h1>
            <button
              type="button"
              className="px-6 text-sm py-2 rounded-3xl border border-gray-700"
            >
              <a href={modalState?.soundcloud} target="_blank" rel="noreferrer">
                Play Now
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongModal;
