import { useEffect, useRef, useState } from 'react';
import Slick from 'react-slick';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Header from '../components/Header';
import VideoComponent from '../components/VideoComponent';
import { useContextValue } from '../context';
import SongModal from '../components/SongModal';
import SongCard from '../components/SongCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AudioWave from '../components/AudioWave/AudioWave';
import { sanityClient } from '../sanity';

function Home() {
  const { modalState, isPlay } = useContextValue();
  const sliderRef = useRef();
  const videoRef = useRef();
  const [muted, setMuted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [homeData, setHomeData] = useState({});
  const muteVideo = () => {
    videoRef.current.muted = true;
  };

  const handleDataLoad = () => {
    setLoaded(true);
    videoRef.current.play();
  };

  useEffect(() => {
    const fetchData = async () => {
      const gql = `
      *[_type == "home"][0]{
        Photo{
          asset->{url}
      },
      newVideoLogo{
        asset->{url}
      },
      releases[]->{
        ...,
        thumbnail{
        asset->{url}
      }
      },
newVideoPoster{
  asset->{url}
},
videoPoster{
  asset->{url}
},
newVideoLogo{
  asset->{url}
},
Video,
newVideo,
about,
      
 }
      `;
      const res = await sanityClient.fetch(gql);
      setHomeData(res);
    };
    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth < 768 ? 3 : 5,
    slidesToScroll: 1,
    draggable: true,
    centerPadding: 2,
    centerMode: true,
    className: window.innerWidth > 768 && 'innerSlider',
    useTransform: true,
    variableWidth: true,
    adaptiveHeight: true,
    arrows: false,
  };

  if (!homeData) {
    return (
      <div className="relative z-10">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="relative z-10">
      <div className="absolute z-10 w-full">
        <Header index="home" />
      </div>

      <div className="landingSec flex flex-col md:flex-row justify-between">
        <div className="leftImg flex-1 relative mt-[20px] md:mt-0 md:-top-40">
          <div className="w-full h-[400px] md:h-[750px]">
            <video
              ref={videoRef}
              autoPlay
              poster={homeData?.videoPoster?.asset?.url}
              onLoadedData={handleDataLoad}
              src={homeData?.Video}
              controls={false}
              muted={!muted}
              onClick={() => setMuted((prev) => !prev)}
              playsInline
              loop
              className="h-full wow animate__fadeIn cursor-pointer top-4  bg-transparent"
            />
          </div>
          <div className="about w-full md:ml-20 flex items-start justify-center flex-col md:relative wow animate__animated animate__fadeInUp">
            <div className="w-full mx-auto md:w-10/12 md:ml-2">
              {/* <img
                src={homeData?.aboutLogo?.asset?.url}
                alt="about"
                className="md:w-8/12 md:block md:ml-0 w-6/12 md:mr-auto mx-auto"
              /> */}
              <h1 className="text-primary-200 text-6xl font-semibold text-center">
                about
              </h1>
              <p className="w-full text-xl md:w-full px-8 md:px-0 text-center md:text-left h-full">
                {homeData?.about}
              </p>
            </div>
          </div>
        </div>

        <div className="flex mt-[80px] md:mt-[130px] 2xl:mt-[220px] flex-col flex-1 items-center ">
          <div className="w-full relative overflow-hidden top-[80px] z-[5] md:top-0 md:z-0">
            {/* <img
              src={homeData?.djKabooLogo?.asset?.url}
              loading="lazy"
              alt="DJKABOO"
              className="h-20 md:h-28 block mx-auto"
            />
            <span className="w-10/12 h-28 absolute top-0 bg-black wow slideOutDownx" /> */}
          </div>
          <img
            src={homeData?.Photo?.asset?.url}
            loading="lazy"
            alt="Kabo Logo"
            className="relative wow animate__animated animate__fadeIn bottom-20 md:top-10 w-full md:w-1/2 d-block mx-auto"
          />
        </div>
      </div>

      <div className="relative md:mt-[200px] w-full flex items-center flex-col wow animate__fadeInUp">
        <div className=" relative releaseBorder">
          <h1 className="text-5xl md:text-7xl font-semibold text-primary-200 uppercase tracking-wide">
            releases
          </h1>
        </div>
        <AudioWave />
        <span className="w-7/12 md:w-3/12 relative releaseBorder" />
        {modalState.releasetitle && <SongModal />}

        <div className="cards relative w-11/12 pt-20 flex items-center wow animate__fadeInUp">
          {/* <Swiper /> */}
          <span
            className="h-6 w-6 md:h-10 md:w-10 rounded-full flex items-center justify-center cursor-pointer text-black bg-primary-200 border"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <ArrowLeftIcon />
          </span>

          <Slick
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...settings}
            ref={sliderRef}
            className="w-10/12 md:w-11/12 mx-auto"
          >
            {homeData?.releases &&
              homeData?.releases?.map((songData, index) => (
                <SongCard
                  songData={songData}
                  key={songData?.thumbnail?.asset?.url}
                />
              ))}
          </Slick>
          <span
            className="h-6 w-6 md:h-10 md:w-10 rounded-full  flex items-center justify-center  cursor-pointer text-black bg-primary-200 border"
            onClick={() => sliderRef.current.slickNext()}
          >
            <ArrowRightIcon />
          </span>
        </div>
      </div>

      <div className="relative w-full flex items-center flex-col h-full mt-[150px] wow animate__fadeInUp">
        {/* <img
          src={homeData?.newVideoLogo?.asset?.url}
          className="w-10/12 md:w-[40%] relative releaseBorder"
          alt=""
        /> */}

        <div className="relative releaseBorder flex flex-col items-center justify-center">
          <p className="text-7xl mb-4">Marvel</p>
          <h1 className="text-5xl md:text-7xl font-semibold text-primary-200 uppercase tracking-wide">
            moon knight
          </h1>
        </div>

        <AudioWave />
        {/* Border  */}
        <span className="w-7/12 md:w-3/12 relative releaseBorder" />
        <VideoComponent
          homeVideo={muteVideo}
          videoUrl={homeData?.newVideo}
          videoThubmnail={homeData?.newVideoPoster?.asset?.url}
        />
      </div>

      <div className="footer pt-32 pb-20 flex flex-col justify-center items-center">
        <h1 className="text-lg text-center">
          &copy; Djkaboo {new Date().getFullYear()} All Right Reserved
        </h1>
        <p className="text-gray-400">
          Powered by{' '}
          <a
            href="https://www.aseel-ibrahim.com"
            target="_blank"
            rel="noreferrer"
            className="text-black"
          >
            Aseel Ibrahim
          </a>
        </p>
      </div>
    </div>
  );
}

export default Home;
