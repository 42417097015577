import { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../assets/Logo-[Converted].png';
import { useContextValue } from '../context';

function Header({ index }) {
  const [showSideBar, setShowSideBar] = useState(false);
  const isMobile = window.innerWidth < 768;
  const { links } = useContextValue();
  return (
    <div
      className={`flex justify-between md:justify-around  md:mb-0 flex-row md:flex-row md:w-full md:mx-auto items-center md:pt-[50px] ${
        !isMobile && 'wow animate__fadeIn'
      }`}
    >
      <div className="hidden md:flex space-x-2 lg:space-x-14 md:flex-[2] justify-center">
        <Link
          to="/"
          className={`headerLink ${index === 'home' && 'text-primary-200'}`}
        >
          Home
        </Link>
        <Link
          to="/videos"
          className="headerLink"
          style={{ color: `${index === 'videos' && 'text-primary-200'}` }}
        >
          Videos
        </Link>
        <Link
          to="/press"
          className="headerLink"
          style={{ color: `${index === 'press' && 'text-primary-200'}` }}
        >
          Press
        </Link>
        <Link
          to="/download"
          className="headerLink"
          style={{ color: `${index === 'download' && 'text-primary-200'}` }}
        >
          Download
        </Link>
        <Link
          to="/contact"
          className="headerLink"
          style={{ color: `${index === 'contact' && 'text-primary-200'}` }}
        >
          Contact
        </Link>
      </div>

      {/* {window.innerWidth < 768 && (
        
      )} */}

      <div
        className={`h-full md:hidden flex flex-col w-full bg-primary-100 fixed left-0 top-0 z-30 ease-in-out duration-700 ${
          showSideBar ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex flex-col justify-center mx-3">
          <CloseIcon
            onClick={() => setShowSideBar(false)}
            style={{
              fontSize: 40,
              cursor: 'pointer',
              marginLeft: 'auto',
              display: 'block',
              marginTop: 15,
            }}
          />
          <img
            src={Logo}
            className="w-18 block mb-4 mx-auto invert"
            alt="KaboLogo"
          />
        </div>

        <div className="flex flex-col items-center space-y-6">
          <Link
            to="/"
            className={`headerLink ${index === 'home' && 'text-primary-200'}`}
            // style={{ color: `` }}
          >
            Home
          </Link>
          <Link
            to="/videos"
            className={`headerLink ${index === 'videos' && 'text-primary-200'}`}
          >
            Videos
          </Link>
          <Link
            to="/press"
            className={`headerLink ${index === 'press' && 'text-primary-200'}`}
          >
            Press
          </Link>
          <Link
            to="/download"
            className={`headerLink ${
              index === 'download' && 'text-primary-200'
            }`}
          >
            Download
          </Link>
          <Link
            to="/contact"
            className={`headerLink ${
              index === 'contact' && 'text-primary-200'
            }`}
          >
            Contact
          </Link>
        </div>

        <div className="flex items-center justify-center mt-20 space-x-5 ">
          <a href={links?.spotifylink} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-green-400 fa-brands text-xl fa-spotify " />
          </a>
          <a href={links?.instagramlink} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-red-600 opacity-80 text-xl fa-brands fa-instagram" />
          </a>
          <a href={links?.youtubelink} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-red-600 text-xl fa-brands fa-youtube" />
          </a>
          <a href={links?.applemusic} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-[#E85CF4] text-xl fa-brands fa-itunes-note" />
          </a>
          <a href={links?.soundcloudlink} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-orange-500 text-xl fa-brands fa-soundcloud" />
          </a>
          <a href={links?.bandcamplink} target="_blank" rel="noreferrer">
            <i className="cursor-pointer hover:text-blue-400 text-xl fa-solid fa-compass" />
          </a>
        </div>
      </div>

      <span className="md:hidden">
        <MenuIcon
          onClick={() => setShowSideBar(true)}
          style={{
            fontSize: 30,
            cursor: 'pointer',
            marginLeft: 10,
            marginTop: 15,
          }}
        />
      </span>

      <div className="leftSide flex items-center justify-end md:justify-start flex-[1] md:mr-4">
        <img
          src={Logo}
          className="h-12 my-2 md:h-14 lg:h-16 xl:h-28"
          alt="KaboLogo"
        />

        {/* <h1 className="hidden md:block md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">
          DJ KABOO
        </h1> */}
      </div>
    </div>
  );
}

export default Header;
