import { useEffect, useRef, useState } from 'react';
import { useContextValue } from '../context';

function VideoComponent({ homeVideo, videoUrl, videoThubmnail }) {
  const videoRef = useRef();
  const [seen, setSeen] = useState(false);
  const { stopAudio } = useContextValue();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          stopAudio();
          homeVideo();
          setSeen(true);
          setTimeout(() => {
            videoRef?.current?.play();
          }, 2000);
        } else {
          videoRef?.current?.pause();
          setSeen(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(videoRef.current);
  }, [homeVideo, stopAudio]);

  return (
    <div
      onClick={() => {
        videoRef.current.muted = !videoRef.current.muted;
      }}
      className="w-full mt-10  relative p-3 overflow-hidden"
    >
      {/* shadow-[#BC00FF] shadow-sm  */}
      <video
        controls={false}
        src={videoUrl}
        ref={videoRef}
        playsInline
        muted
        poster={videoThubmnail}
        loop
        className="w-full md:w-10/12 mx-auto h-[630px] 2xl:h-[1000px] mt-12 object-contain"
      />
      {/* Shadow */}
    </div>
  );
}

export default VideoComponent;
