import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import PressComponent from '../components/Press/PressComponent';
import { sanityClient } from '../sanity';
import '../styles/Press.css';

function Press() {
  const [data, setData] = useState([]);

  useEffect(() => {
    let subscribe = true;
    const gql = `*[_type == "press"]{
      ...,
      photo{
       asset->{url}
      }
    }`;

    const fetchData = async () => {
      const res = await sanityClient.fetch(gql);
      setData(res);
    };
    // eslint-disable-next-line no-unused-expressions
    subscribe && fetchData();

    // eslint-disable-next-line no-return-assign
    return () => (subscribe = false);
  }, []);
  return (
    <div className="min-h-screen relative z-10 pb-20 w-screen">
      <div className="w-full">
        <Header index="press" />
      </div>
      <div className="mx-12 md:mt-[70px] md:mb-[0px] h-full md:w-[950px] md:mx-auto pressGrid2">
        {data?.length > 0 &&
          data?.map((press, index) => (
            <PressComponent press={press} key={press?.photo?.asset?.url} />
          ))}
      </div>
    </div>
  );
}

export default Press;
