import { useEffect, useState } from 'react';
import Header from '../components/Header';
import { sanityClient } from '../sanity';

function Download() {
  const [data, setData] = useState({});

  useEffect(() => {
    let subscribe = true;
    const gql = `*[_type == "download"][0]{
            ...,
            photo{
            asset->{url}
          }
          }`;
    const fetchData = async () => {
      const res = await sanityClient.fetch(gql);
      setData(res);
    };
    // eslint-disable-next-line no-unused-expressions
    subscribe && fetchData();

    // eslint-disable-next-line no-return-assign
    return () => (subscribe = false);
  }, []);

  return (
    <div className="min-h-screen relative z-10 w-screen">
      <div className="w-full">
        <Header index="download" />
      </div>
      <div className="py-20 flex justify-around flex-col space-y-20 md:space-y-0 md:flex-row items-center w-full">
        <div className="left relative md:w-[55%] flex justify-center md:justify-end">
          <img
            loading="lazy"
            src={data?.photo?.asset?.url}
            className="w-3/4 wow animate__slideInLeft"
            alt="homeAlt"
          />
        </div>
        <div className="right pb-32 md:pb-0 md:ml-44 w-full flex flex-col items-center md:block md:w-[45%] space-y-5 wow animate__slideInRight">
          <div className=" w-[250px] md:w-[300px] cursor-pointer border-primary-200 border-2  hover:bg-primary-200">
            <a
              href={data?.logo}
              rel="noreferrer"
              className="w-full"
              target="_blank"
              download
            >
              <h1 className="hover:text-black text-center text-base text-primary-200 px-5 py-4 font-semibold uppercase">
                Download Logo
              </h1>
            </a>
          </div>
          <div className=" w-[250px] md:w-[300px] cursor-pointer border-primary-200 border-2  hover:bg-primary-200">
            <a
              href={data?.photos}
              rel="noreferrer"
              className="w-full"
              target="_blank"
              download
            >
              <h1 className="hover:text-black text-center text-base text-primary-200 px-5 py-4 font-semibold uppercase">
                Download Photos / Videos
              </h1>
            </a>
          </div>
          {/* #ffffffdb */}
          <div className="w-[250px] md:w-[300px] cursor-pointer border-primary-200 border-2  hover:bg-primary-200">
            <a
              href={data?.kit}
              rel="noreferrer"
              className="w-full"
              target="_blank"
              download
            >
              <h1 className="hover:text-black text-center text-base text-primary-200 px-5 py-4 font-semibold uppercase">
                Download Press Kit
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
