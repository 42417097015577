import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Header from '../components/Header';
import { sanityClient } from '../sanity';

function Videos() {
  const [isMobile, setIsMobile] = useState(false);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const fetchData = async () => {
      const gql = `*[_type == "videospage"]{
        ...
      }`;
      const res = await sanityClient.fetch(gql);
      setVideos(res.sort((a, b) => (a?.videoIndex > b?.videoIndex ? 1 : -1)));
    };
    fetchData();
  }, []);

  return (
    <div className="Videos flex flex-col items-center min-h-screen relative overflow-hidden bg-primary-100 z-10 w-screen">
      <div className="w-full ">
        <Header index="videos" />
      </div>
      <div className="mt-20 h-full space-y-24">
        {videos.map((videoObj) => (
          <div
            key={videoObj?.videoName}
            className="flex flex-col items-center justify-center"
          >
            <h1 className="text-2xl text-center md:text-4xl mb-6">
              {videoObj?.videoName}
            </h1>
            <ReactPlayer
              key={videoObj?.videoLink}
              url={videoObj?.videoLink}
              controls
              playsinline
              className="rounded-lg h-[500px]"
              width={isMobile ? '400px' : '700px'}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Videos;
