import React from 'react';
import PhotoI from '../../assets/IMG_4317.JPG';

function PressComponent({ press }) {
  return (
    <div className="w-full h-[500px] pressComponent relative rounded-lg overflow-hidden group wow animate__fadeInUp ">
      <img
        src={press?.photo?.asset?.url}
        alt="Post Thumb"
        className="h-full object-cover w-full"
      />
      <div className="absolute items-end justify-center top-0 left-0 w-full h-full z-20 bg-[#000000bf] hidden cursor-pointer group-hover:flex">
        <a
          href={press?.blogLink}
          target="_blank"
          className="w-full"
          rel="noreferrer"
        >
          <button
            type="button"
            className="w-full py-5 bg-[#00000094] uppercase text-white font-semibold"
          >
            See More
          </button>
        </a>
      </div>
    </div>
  );
}

export default PressComponent;
