import {
  createContext,
  useRef,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import { sanityClient } from '../sanity';

const ContextData = createContext({});

function ContextProvider({ children }) {
  const [src, setSrc] = useState('');
  const [background, setBackground] = useState({});
  const [isPlay, setIsPlay] = useState(false);
  const audioFile = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [modalState, setModalState] = useState({});
  const [links, setLinks] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const gql = `
            *[_type == "home"][0]{
            background{
              asset->{url}
            }
            }
            `;
      const res = await sanityClient.fetch(gql);
      setBackground(res.background);
    };
    fetchData();

    const gql2 = `
          *[_type == "audiobar"][0]{
              ...
            }`;

    const fetchData2 = async () => {
      const res = await sanityClient.fetch(gql2);
      setLinks(res);
    };
    fetchData2();

    const wave = WaveSurfer.create({
      container: '.waves',
      height: 20,
      waveColor: '#dde5ec',
      progressColor: 'black',
      responsive: true,
      hideScrollbar: true,
      barRadius: 3,
      barWidth: 3,
      barGap: 6,
      loopSelection: false,
      cursorColor: 'transparent',
    });
    audioFile.current = wave;

    if (src) {
      audioFile.current.load(src);
    }

    return () => wave.destroy();
  }, [src]);

  useEffect(() => {
    if (audioFile.current) {
      audioFile.current.on('finish', () => {
        setIsPlay(false);
      });
    }
  }, [src]);

  const stopAudio = useCallback(() => {
    if (audioFile.current.isPlaying) {
      setIsPlay(false);
      audioFile.current.pause();
    }
  }, []);

  const playAudio = useCallback((val) => {
    if (audioFile.current.isReady) {
      setIsPlay(val);
      audioFile.current.playPause();
    }
  }, []);

  const contextValues = useMemo(
    () => ({
      src,
      currentTime,
      setSrc,
      playAudio,
      isPlay,
      stopAudio,
      modalState,
      setModalState,
      audioFile,
      background,
      links,
    }),
    [
      src,
      currentTime,
      setSrc,
      playAudio,
      isPlay,
      stopAudio,
      modalState,
      setModalState,
      audioFile,
      background,
      links,
    ]
  );

  return (
    <ContextData.Provider value={contextValues}>
      {children}
      {/* <audio src='' onLoadedData={()=>SettingsApplicationsRounded()} /> */}
    </ContextData.Provider>
  );
}

const useContextValue = () => useContext(ContextData);

export { useContextValue, ContextProvider };
