import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PauseSharpIcon from '@mui/icons-material/PauseSharp';
import { useEffect, useState } from 'react';
import { useContextValue } from '../../context';
import { sanityClient } from '../../sanity';

function AudioComponent() {
  const { playAudio, isPlay, audioFile, setSrc } = useContextValue();
  const [audioBar, setAudioBar] = useState({});
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    const gql = `*[_type == "audiobar"][0]{
            ...,
            photo{
              asset->{url}
            },
            trackfile{
              asset->{url}
            }
          
          }`;
    const fetchData = async () => {
      const res = await sanityClient.fetch(gql);
      setAudioBar(res);
      setSrc(res?.trackfile?.asset?.url);
    };
    fetchData();
    const timeTracker = setInterval(() => {
      if (audioFile?.current?.isReady) {
        setCurrentTime(audioFile.current.getCurrentTime().toFixed(0));
      }
    }, 400);

    return () => clearInterval(timeTracker);
  }, [audioFile, setSrc]);

  return (
    <div className="w-full bg-primary-100 border-t border-[#ffffff0d] sticky flex items-center -bottom-[1px] z-30 before:rounded-t-xl h-20">
      <div className="leftSide flex-1 flex items-center justify-between">
        <div className="flex justify-between items-center space-x-3 ml-6">
          <img
            src={audioBar?.photo?.asset?.url}
            className="h-10 w-10 pressComponent rounded-xl"
            alt="thumbnail"
          />
          <div className="flex flex-col text-black">
            <span className="font-semibold text-sm md:text-base uppercase">
              {audioBar?.trackname}
            </span>
            <span className="uppercase text-sm md:text-base">
              {audioBar?.djname}
            </span>
          </div>
        </div>

        <div className="playButtons mx-12 flex items-center text-black">
          <SkipPreviousIcon style={{ fontSize: 35, cursor: 'pointer' }} />
          {isPlay ? (
            <span className="bg-primary-200 h-10 w-10 flex items-center justify-center rounded-[50%]">
              <PauseSharpIcon
                onClick={() => playAudio(false)}
                style={{ fontSize: 32, cursor: 'pointer' }}
              />
            </span>
          ) : (
            <span className="bg-primary-200 h-10 w-10 flex items-center justify-center rounded-[50%]">
              <PlayArrowSharpIcon
                onClick={() => playAudio(true)}
                style={{ fontSize: 32, cursor: 'pointer' }}
              />
            </span>
          )}
          <SkipNextIcon style={{ fontSize: 35, cursor: 'pointer' }} />
        </div>

        {/* <div className="flex flex-col flex-1"> */}
        <div className="mx-10 hidden md:flex md:flex-col-reverse md:flex-1 pt-4 waves">
          <div className=" hidden md:flex items-center md:w-full justify-between">
            <small className="text-black">
              {(currentTime / 60).toString()[0]}:
              {currentTime % 60 < 10
                ? `${`0${currentTime % 60}`}`
                : currentTime % 60}
            </small>
            <small className="text-black opacity-75 ">
              {((audioFile?.current?.getDuration() || 0) / 60).toString()[0]}:
              {(audioFile?.current?.getDuration() || 0) % 60 < 10
                ? `${`0${
                    (
                      (audioFile?.current?.getDuration() || 0) % 60
                    ).toString()[0]
                  }`}`
                : ((audioFile?.current?.getDuration() || 0) % 60).toString()[0]}
            </small>
          </div>
        </div>
      </div>
      <div className="mr-4 hidden md:block">
        <a href={audioBar?.spotifylink} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-green-400 opacity-75 fa-brands mx-3 text-lg fa-spotify " />
        </a>
        <a href={audioBar?.instagramlink} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-[#AB7EEB] mx-3 opacity-75 text-lg fa-brands fa-instagram" />
        </a>
        <a href={audioBar?.youtubelink} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-red-600 mx-3 opacity-75 text-lg fa-brands fa-youtube" />
        </a>
        <a href={audioBar?.applemusic} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-[#E85CF4] mx-3 opacity-75 text-lg fa-brands fa-itunes-note" />
        </a>
        <a href={audioBar?.soundcloudlink} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-orange-500 mx-3 opacity-75 text-lg fa-brands fa-soundcloud" />
        </a>
        <a href={audioBar?.bandcamplink} target="_blank" rel="noreferrer">
          <i className="cursor-pointer text-black hover:text-blue-300 mx-3 opacity-75 text-lg fa-solid fa-compass" />
        </a>
      </div>
    </div>
  );
}

export default AudioComponent;
