import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import WOW from 'wow.js';
import Home from './pages/Home';
import AudioComponent from './components/Audio/AudioComponent';
import Download from './pages/Download';
import Contact from './pages/Contact';
import NotFound from './pages/404';
import Logo from './assets/Logo-[Converted].png';
import Press from './pages/Press';
import Videos from './pages/Videos';
import './styles/animate.css';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const wow = new WOW();

    wow.init();
    const x = setTimeout(() => {
      setIsLoaded(true);
    }, 3000);

    return () => clearTimeout(x);
  }, []);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="App bg-primary-100">
      {!isLoaded && (
        <div className="bg-primary-100 w-screen h-screen fixed top-0 left-0 z-30" />
      )}

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/download" element={<Download />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/press" element={<Press />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>

        {!isLoaded && isMobile && (
          <div className="splashScreen bg-primary-100 fixed w-screen h-screen top-0 left-0 z-40" />
        )}

        <div
          className={`splashScreen bg-primary-100 fixed w-screen h-screen top-0 left-0 ease-in-out animated ${
            !isLoaded ? 'animate__slideInUp' : 'animate__slideOutUp'
          } z-40 flex`}
        >
          <img
            src={Logo}
            alt="Logo"
            className={`ease-in-out w-[250px] opacity-0 duration-500 ${
              isLoaded ? 'opacity-0' : 'opacity-100'
            }`}
          />
        </div>

        <AudioComponent />
      </Router>
    </div>
  );
}

export default App;
